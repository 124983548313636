import "./styles.css";
import Slider from "infinite-react-carousel";
import Spline from "@splinetool/react-spline";
import { motion, useScroll } from "framer-motion/dist/framer-motion";
import disableScroll from "disable-scroll";
import React, { useState, useEffect } from "react";
import "./app.css"
import About from "./About";
import Why from "./Why";
import Brands from "./Brands";
import CTA from "./CTA";
import Plattform from "./Platform";
import Lowbar from "./Lowbar";
import Landing from "./Landing";

export default function App() {
  const [enableScroll, setEnableScroll] = useState(true);
  const { scrollYProgress } = useScroll();
  disableScroll.off();
  if (enableScroll) {
    disableScroll.on();
  } else {
    disableScroll.off(); // prevent scrolling
  }

  useEffect(() => {
    let timer1 = setTimeout(() => setEnableScroll(false), 3000);
    return () => {
      clearTimeout(timer1);
      setEnableScroll(enableScroll);
    };
  }, []);

  return (
    <div
      style={{
        background: "#161616",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Landing />
      <Brands />
      <About />
      <Why />
      <CTA />
      <Plattform />
      <div style={{ height: 30, width: 1920 }}>
        {!enableScroll ? (
          <img
            style={{
              width: 30,
              position: "fixed",
              top: 800,
              left: "49%",
              opacity: "50%",
              zIndex: 1,
            }}
            src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/scroll_icon.png"
          />
        ) : (
          ""
        )}
      </div>
      <div className="hide_scroll">
        <div
          style={{
            background: "#707070",
            height: 650,
            width: 3,
            position: "fixed",
            top: 200,
            left: 130,
            opacity: "80%",
            borderRadius: "10px 10px 10px 10px",
            boxShadow: "0px 0px 5px 2px #161616B3",
          }}
        />
        <motion.div
          className="progress-bar"
          style={{ scaleY: scrollYProgress }}
        />
        <div
          style={{
            background: "#8F1111",
            height: 120,
            width: 120,
            position: "fixed",
            top: 50,
            left: 75,
            borderRadius: 60,
            opacity: "100%",
            zIndex: 1000324023043,
            boxShadow: "0px 0px 5px 2px #161616B3",
          }}
        ></div>
        <img
          style={{
            width: 100,
            marginLeft: 50,
            padding: 0,
            position: "fixed",
            top: 100,
            left: 35,
            borderRadius: 0,
            opacity: "100%",
            zIndex: 10003240230,
          }}
          src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/ADLY_LOGO_2022_OFFICIAL.png"
        />
        <Lowbar />
      </div>
      <div style={{ color: "#fff", position: "absolute", top:"5%", right: "6.5%" }}>
      <button className="brand-login" onClick={() => window.open('https://streamer.adly.gg' , "_self")} style={{marginRight: 10}}>Login | Streamers</button>
        <button className="brand-login" onClick={() => window.open('https://app.adly.gg' , "_self")}>Login | Brands</button>
        {/* login */}
      </div>
    </div>
  );
}
