import React from "react";
import Spline from "@splinetool/react-spline";
import { BsFillForwardFill } from "react-icons/bs";
import "./platform.css";
export default function Plattform() {
  return (
    <div style={{ background: "#0e0e0e", paddingTop: "10%", paddingLeft: "5%" }}>
      <div className="image_and_text2">
        <div style={{ marginRight: "5%", marginLeft: "5%", width: "75%", minWidth: 300 }}>
          <img
            src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/platform-web.png"
            style={{
              width: "100%",
              marginTop: "5%"
            }}
          />
        </div>
        <div
          style={{
            height: 580,
            opacity: "100%",
            color: "#fff",
            width: "100%",
            zIndex: 0,
            display: "flex",
            marginLeft: "5%"
          }}
          className="flex-block"
        >
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "block" }}>
                <div
                  style={{ display: "flex", marginLeft: 0, maxWidth: "80%" }}
                >
                  <div
                    className="body"
                    style={{
                      marginTop: 35,
                      fontSize: 40,
                      fontWeight: 400,
                      zIndex: 0,
                    }}
                  >
                    The Adly
                  </div>
                  <div
                    className="body"
                    style={{
                      marginTop: 35,
                      marginLeft: 10,
                      fontSize: 40,
                      fontWeight: 800,
                      zIndex: 0,
                    }}
                  >
                    Platform
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body,text_width"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: 400,
                      display: "flex",
                      zIndex: 100000,
                    }}
                  >
                    All our campaigns are created using five simple steps on The
                    Adly Platform.
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: 400,
                      display: "flex",
                      zIndex: 100000,
                    }}
                  >
                    1. Choose between three campaign objectives.
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: 400,
                      display: "flex",
                      zIndex: 100000,
                    }}
                  >
                    2. Specify your target audience.
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 20,
                      fontWeight: 400,
                      zIndex: 100000,
                    }}
                  >
                    3. Upload campaign assets.
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 20,
                      fontWeight: 400,
                      zIndex: 100000,
                    }}
                  >
                    4. Identify and invite creators.
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="body"
                    style={{
                      maxWidth: "80%",
                      fontSize: 16,
                      marginTop: 20,
                      fontWeight: 400,
                      zIndex: 1000,
                    }}
                  >
                    5. Real-time monitoring and estimated results.
                    <a
                      href={"mailto:gustav@adly.gg"}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <button className="button" style={{ marginLeft: 0 }}>
                        REQUEST A DEMO
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
