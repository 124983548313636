import React from "react";
import "./styles.css";
import "./lowbar.css"
import { BsLinkedin } from "react-icons/bs";
export default function Lowbar() {
  return (
    <div
      className="body"
      style={{ height: 250, width: 1920, background: "#161616", color: "#fff" }}
    >
      <div style={{ display: "flex" }}>
        <div className="phone_margin" style={{ padding: 30, display: "block" }}>
          <img
            style={{
              width: 100,
              marginBottom: 10,
            }}
            src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/ADLY_LOGO_2022_OFFICIAL.png"
          />
          <div style={{ marginBottom: 10 }}>Adly AB </div>
          <div style={{ marginBottom: 10 }}>
            Teknikringen 7, 583 30 Linkoping, Sweden
          </div>
          <div style={{ marginBottom: 10 }}>Copyright 2022</div>

            <a
              href={"https://www.linkedin.com/company/adlyab/"}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <BsLinkedin size={30} style={{ cursor: "pointer",color: "#fff" }} />
            </a>

        </div>
      </div>
    </div>
  );
}
