import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-189117851-1"
ReactGA.initialize(TRACKING_ID);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
