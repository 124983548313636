import React from "react";
import "./landing.css";

export default function Landing() {
  return (
    <div className="landing">
      <iframe
        className="iframe"
        src="https://my.spline.design/untitled-fac0a09af31065d8f09b0f2eee2ab651/"
        frameBorder={0}
      />
    </div>
  );
}
