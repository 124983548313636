import React, { Component } from "react";
import "./styles.css";
import Spline from "@splinetool/react-spline";
import "./why.css";

class Why extends Component {
  render() {
    return (
      <div>
        <div
          style={{ position: "absolute", height: 900 }}
          className="spline_size"
        >
          <Spline scene="https://prod.spline.design/LXD8LPJA9XYESlJz/scene.splinecode" />
        </div>
        <div
          className="color"
          style={{
            color: "#fff",
            width: "100%",
            zIndex: 0,
            display: "flex",
            paddingBottom: "10%"
          }}
        >
          <div className="why" style={{ marginTop: 75, color: "#fff" ,zIndex: 0}}>
            <div className="body2" style={{ zIndex: 0}}>
              <div
                style={{
                  width: 350,
                  fontSize: "max(1.5vw, 16px)",
                  marginTop: 50,
                  fontWeight: 800,
                  zIndex: 0,
                  display: "flex",
                }}
              >
                THE AUDIENCE {"&"} THEIR GENERAL APPROACH TOWARDS MARKETING
              </div>
            </div>
            <div
              style={{ display: "block", marginLeft: "10%", marginTop: "10%" }}
            >
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="body"
                  style={{
                    fontSize: "max(5vw, 60px)",
                    marginTop: 25,
                    fontWeight: 800,
                    zIndex: 0,
                  }}
                >
                  0.5B
                </div>
                <div
                  className="body"
                  style={{
                    fontSize: "max(1.5vw, 16px)",
                    marginLeft: 10,
                    marginTop: 25,
                    fontWeight: 400,
                    zIndex: 0,
                  }}
                >
                  monthly viewers
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginTop: 75,
                }}
              >
                <div
                  className="body"
                  style={{
                    fontSize: "max(5vw, 60px)",
                    marginTop: 0,
                    fontWeight: 800,
                    zIndex: 0,
                  }}
                >
                  76%
                </div>
                <div
                  className="body"
                  style={{
                    fontSize: "max(1.5vw, 16px)",
                    marginLeft: 10,
                    marginTop: 0,
                    fontWeight: 400,
                    zIndex: 0,
                    // width: 200,
                  }}
                >
                  aged 16 - 35
                </div>
              </div>
            </div>
            <div style={{ display: "block", marginLeft: "10%", marginTop: "10%"  }}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="body"
                  style={{
                    fontSize: "max(5vw, 60px)",
                    marginTop: 25,
                    fontWeight: 800,
                    zIndex: 0,
                  }}
                >
                  57%
                </div>
                <div
                  className="body"
                  style={{
                    fontSize: "max(1.5vw, 16px)",
                    marginLeft: 10,
                    marginTop: 25,
                    fontWeight: 400,
                    zIndex: 0,
                  }}
                >
                  use adblock
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div
                  className="body"
                  style={{
                    fontSize: "max(5vw, 60px)",
                    marginTop: 75,
                    fontWeight: 800,
                    zIndex: 0,
                  }}
                >
                  77%
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div
                    className="body"
                    style={{
                      fontSize: "max(1.5vw, 16px)",
                      marginLeft: 10,
                      fontWeight: 400,
                      zIndex: 1,
                      display: "flex",
                      // width: 200,
                    }}
                  >
                    appreciate authentic ads
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Why;