import React, { Component } from "react";
import "./styles.css";
import "./about.css";
import Spline from "@splinetool/react-spline";

class About extends Component {
  render() {
    return (
      <div className="background">
        <div className="sorted">
          <div className="image_and_text">
            <div style={{ maxWidth: 500, marginRight: "5%" }}>
              <img
                style={{
                  width: "100%",
                  marginTop: 0,
                  boxShadow: "0px 0px 5px 2px #161616B3",
                  borderRadius: 10,
                }}
                src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/web-test.gif"
              />
            </div>
            <div className="text-section" style={{ maxWidth: 500 }}>
              <div style={{ display: "flex", maxWidth: "50%"}}>
                <div
                  className="body"
                  style={{
                    marginTop: 25,
                    Left: 25,
                    fontSize: 40,
                    fontWeight: 400,
                  }}
                >
                  Who
                </div>
                <div
                  className="body"
                  style={{
                    marginTop: 25,
                    marginLeft: 10,
                    fontSize: 40,
                    fontWeight: 400,
                  }}
                >
                   Are
                </div>
                <div
                  className="body"
                  style={{
                    marginTop: 25,
                    marginLeft: 10,
                    fontSize: 40,
                    fontWeight: 800,
                  }}
                >
                  Adly?
                </div>
              </div>
              <div
                className="body"
                style={{
                  maxWidth: 500,
                  fontSize: 16,
                  marginTop: 25,
                  fontWeight: 400,
                }}
              >
                We connect brands with Gen Z through our live streaming
                advertising technology. Our solution enable brands to reach and
                engage Gen Z's through non-intrusive, creative and interactive
                ads.
              </div>
                <a
                  href={"mailto:gustav@adly.gg"}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <button className="button" style={{marginLeft: 0}}>REQUEST A DEMO</button>
                </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
