import React, { Component } from "react";
import "./styles.css";
import "./brands.css";
import Slider from "infinite-react-carousel";
class Why extends Component {
  render() {
    return (
      <div className="brands">
        <div className="body, headline">TRUSTED BY</div>
        <Slider
          arrows={false}
          slidesToShow={window.innerWidth < 600 ? 2 : 10}
          autoplay={true}
          // height={20}
          autoplaySpeed={2000}
          style={{
            display: "flex",
            maxWidth: "100%",
            height: 30,
            marginTop: 10,
          }}
        >
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+10.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+11.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+13.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+14.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+16.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+17.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+18.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+19.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+20.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+21.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+22.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+8.png" />
          <img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+9.png" />
        </Slider>
      </div>
    );
  }
}

export default Why;

/*
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+10.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+11.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+12.png" /> 
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+13.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+14.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+15.png" /> 
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+16.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+17.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+18.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+19.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+20.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+21.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+22.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+7.png" /> 
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+8.png" />
<img src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/website/Web+1920+%E2%80%93+9.png" />

*/