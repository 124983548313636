import React, { Component } from "react";
import "./styles.css";
import "./cta.css";
import Slider from "infinite-react-carousel";
import Spline from "@splinetool/react-spline";

class Why extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            height: "auto",
            overflow: "hidden",
            position: "absolute",
            zIndex: 0,
          }}
          className="video_size"
        >
          <video
            style={{
              width: "100%",
              height: "auto",
              zIndex: 0,
            }}
            src="https://adly-campaigns.s3.eu-north-1.amazonaws.com/galen-web-bg.mp4"
            autoPlay
            muted
            loop
          />
        </div>
        <div
          style={{
            background: "#000000",
            maxWidth: "100%",
            // height: 771,
            overflow: "hidden",
            opacity: "75%",
            padding: 50,
            color: "#fff",
          }}
          className="area_size"
        >
          <div className="center_cta">
            <div
              className="body,cta_text"
              style={{
                marginTop: 10,
                fontSize: "max(1.5vw, 16px)",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              Learn more about our
            </div>
            <div className="cta_text">
              <div
                className="body,cta_text"
                style={{
                  marginLeft: 10,
                  marginTop: 10,
                  fontSize: "max(1.5vw, 16px)",
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                AI-powered
              </div>
              <div
                className="body,cta_text"
                style={{
                  color: "#e70c0c",
                  marginTop: 10,
                  fontSize: "max(1.5vw, 16px)",
                  fontWeight: 800,
                  textAlign: "center",
                  marginLeft: 10,
                }}
              >
                ad technology
              </div>
            </div>
            <div
              className="body,cta_text"
              style={{
                marginTop: 10,
                fontSize: "max(1.5vw, 16px)",
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              to reach Gen Z
            </div>
            <div className="cta_text">
              <a
                href={"mailto:gustav@adly.gg"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button className="button">REQUEST A DEMO</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Why;
